var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Browse',{attrs:{"columns":[
    'initials',
    'name',
    'tax_code',
    'mail',
    'phone',
    'other_phones',
    'address' ],"formats":{
  },"itemsPerPage":"10","searchOnStart":true,"table":"sales_persons"}})}
var staticRenderFns = []

export { render, staticRenderFns }