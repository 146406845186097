<template>
  <Browse
    :columns="[
      'initials',
      'name',
      'tax_code',
      'mail',
      'phone',
      'other_phones',
      'address',
    ]"
    :formats="{
    }"
    itemsPerPage="10"
    :searchOnStart="true"
    table="sales_persons"
  />
</template>

<script>
import Browse from "@/components/Browse.vue";

export default {
  components: {
    Browse,
  },
};
</script>
